<style scoped lang="less">
.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
</style>
<template>
    <div class="brand-module">
        
        <div class="search">
        	<span style="padding-right: 10px;">关键字：</span>
            <el-input v-model="keyword" style="width:200px;margin-right:40px" size="mini" placeholder="活动名称"/>
			<span style="padding-right: 10px;">活动状态：</span>
			<el-select v-model="state" placeholder="请选择" style="width:150px;margin-right:20px" size="mini">
			    <el-option
			      v-for="item in options"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			  </el-select>
            <el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
			<el-button size="mini" @click="getData();">刷新</el-button>
        </div>

        <div class="tab-box">
			<el-table :data="data" v-loading="loading" @row-click="handleRowClick" :row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="Name" label="活动名称" width="400px"></el-table-column>
				<el-table-column prop="" label="活动时间" width="400px">
					<template slot-scope="scope">
						<span>{{scope.row.StartTime}}</span>
						至
						<span>{{scope.row.EndTime}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="活动状态" width="100px">
					<template slot-scope="scope">
						<span v-if="scope.row.ActivityState === 0">未开始</span>
						<span v-if="scope.row.ActivityState === 1">进行中</span>
						<span v-if="scope.row.ActivityState === 2">已结束</span>
					</template>
				</el-table-column>
				
				<div slot="empty">暂无数据，<el-link type="primary" :href="url" target="_blank">点击此处前往添加</el-link></div>
				
			</el-table>
		</div>

        <div class="pagination">
            <el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        </div>

    </div>
</template>
<script>

import {editSelectWheelSurf} from '../../../api/pageManage.js';
export default {
    components: {
        
    },
    props: {
        
    },
    data () {
        return {
            keyword: '',
			state: null,
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
			url: location.origin + '/index.html#/market/lottery',
			
			options: [{
			  value: null,
			  label: '全部'
			}, {
			  value: 0,
			  label: '未开始'
			}, {
			  value: 1,
			  label: '进行中'
			}],
        };
    },
    methods: {
        async getData(){
            const params = {
                Keywords: this.keyword,
				ActivityState: this.state,
                Skip: (this.pageIndex-1)*this.pageSize,
                Take: this.pageSize
            }

            this.loading = true;

            const result = await editSelectWheelSurf(params);

            this.data = result.Result.Results;
            this.total = result.Result.Total;
            this.loading = false;

        },
        pageChange(value){
            this.pageIndex = value;
            this.getData();
        },
		getRowKey(row){
			return row.Id
		},
		handleRowClick(row, event, column){
			this.data = this.data.map(item=>{
				item.checked = item.Id == row.Id;
				return item;
			})
			this.$emit('change', {data:row, type:'truntable', text: row.Name})
		},
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
    },
    dispatch () {
        
    }
};
</script>
